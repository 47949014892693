import React, { Component } from 'react';
import Sectiontitle from '../component/Banner/Sectiontitle';
import Fade from 'react-reveal/Fade';

class Service extends Component {
  render() {
    let jhonData = this.props.jhonData;
    var { wClass } = this.props;
    return (
      <section className={`${wClass}`} id="service">
        <div className="container">
          <Sectiontitle
            Title="Nos services"
            TitleP="Des services qui correspondent à vos exigences  "
          />
          <Fade bottom cascade duration={1000}>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                  <i className="icon-pencil"></i>
                  <a href=".#">
                    <h2 className="t_color">SERVICES D’INGÉNIERIE</h2>
                  </a>
                  <p style={{ textAlign: 'left' }}>
                    Grâce à l’expertise de nos ingénieurs, nous trouvons et
                    mettons en place des solutions concrètes qui sont
                    économiques, efficaces et durables tout en assurant la
                    protection du public.<br></br>
                    <span>
                      - Analyses de risques<br></br>- Attestations techniques
                      <br></br>- Études de faisabilité
                      <br></br>- Élaboration de méthodologies de travail
                      <br></br>- Élaboration de normes d’entretien<br></br>-
                      Formations<br></br>- Gestion de projets<br></br>-
                      Planification de travaux<br></br>- Sécurité des machines
                      <br></br>
                    </span>
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                  <i className="icon-desktop"></i>
                  <a href=".#">
                    <h2 className="t_color">CONCEPTION ET MISE EN PLAN</h2>
                  </a>
                  <p style={{ textAlign: 'left' }}>
                    GPC Solutions propose à ses clients des solutions
                    novatrices, rentables et durables dans le cadre de leurs
                    projets. <br></br>- Conception <br></br>- Analyse par
                    éléments finis
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6">
                <div className="work_item wow fadeInUp" data-wow-delay="0.1s">
                  <i className="icon-presentation"></i>
                  <a href=".#">
                    <h2 className="t_color">PROJETS CLÉ EN MAIN</h2>
                  </a>
                  <p style={{ textAlign: 'left' }}>
                    À l’aide de nos partenaires, nous pouvons vous offrir des
                    solutions clé en main, de la conception jusqu’à
                    l’installation complète de vos projets.<br></br>- Conception
                    <br></br>- Fabrication<br></br>- Installation<br></br>- Test
                    et mise en fonction
                  </p>
                </div>
              </div>
            </div>
          </Fade>
        </div>
      </section>
    );
  }
}

export default Service;
