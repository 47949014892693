const jhonData = {
  name: 'GPC Solutions',
  name2: 'Richard Tea.',
  name3: 'Alexa Johnson',
  role: 'GPC Solutions',
  ptext: 'Creative Designer & Developer located in London.',
  socialLinks: [
    {
      name: 'Facebook',
      url: 'https://www.linkedin.com/company/gpc-génie-conseil/',
      className: 'social_linkedin',
    },
    {
      name: 'twitter',
      url: 'https://www.facebook.com/GPC-Solutions-103111161469438',
      className: 'social_facebook',
    },
    // {
    //   name: 'googleplus',
    //   url: 'http://www.googleplus.com',
    //   className: 'social_googleplus',
    // },
    // {
    //   name: 'pinterest',
    //   url: 'http://www.pinterest.com',
    //   className: 'social_pinterest',
    // },
  ],
  aboutme: 'Qui nous sommes',
  aboutdetails:
    'Nous offrons des solutions aux défis d’entreprises qui sont en pleine expansion et qui ont besoin d’une expertise spécialisée pour réaliser leurs projets. Notre équipe déploie son savoir-faire pour améliorer la qualité et simplifier la conformité réglementaire. Avec notre passion, renforcée par notre expertise dans plusieurs domaines, nous mettons tout en œuvre pour concevoir des solutions qui ont un sens pour nos clients.',
  service: [
    {
      id: 1,
      iconName: 'icon-pencil',
      serviceTitle: 'SERVICES D’INGÉNIERIE',
      sDetails:
        'Grâce à l’expertise de nos ingénieurs, nous trouvons et mettons en place des solutions concrètes qui sont économiques, efficaces et durables tout en assurant la protection du public: \n ' +
        'Analyses de risques, \n ' +
        'Attestations techniques,   \n ' +
        'Études de faisabilité, Élaboration de méthodologies de travail, Élaboration de normes d’entretien, Formations, Gestion de projets, Planification de travaux, Sécurité des machines',
    },
    {
      id: 2,
      iconName: 'icon-desktop',
      serviceTitle: 'CONCEPTION ET MISE EN PLAN',
      sDetails: `GPC Solutions 
      propose à ses 
      clients des solutions novatrices, 
        rentables et durables dans le cadre de leurs projets: Conception
        Analyse par éléments finis
        `,
    },

    {
      id: 3,
      iconName: 'icon-presentation',
      serviceTitle: 'PROJETS CLÉ EN MAIN',
      sDetails:
        'À l’aide de nos partenaires, nous pouvons vous offrir des solutions clé en main, de la conception jusqu’à l’installation complète de vos projets.',
    },
    // {
    //   id: 4,
    //   iconName: 'icon-linegraph',
    //   serviceTitle: 'Strategy',
    //   sDetails:
    //     'There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.',
    // },
    // {
    //   id: 5,
    //   iconName: 'icon-gears',
    //   serviceTitle: 'Support',
    //   sDetails:
    //     'There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.',
    // },
    // {
    //   id: 6,
    //   iconName: 'icon-tools',
    //   serviceTitle: 'We’re experienced',
    //   sDetails:
    //     'There are many variations of pssages of Lorm available, bu in some form, Lorem Ipsum is simply dummy text.',
    // },
  ],
  contact: [
    {
      id: '1',
      title: 'Contact',
      menuItems: [
        // {
        //   id: 1,
        //   icon: 'icon_pin_alt',
        //   text:
        //     'Address: 123 rue Sherbrooke Ouest, bureau 1000, Montreal, QC, CA, J4H 4E5',
        // },
        {
          id: 2,
          icon: 'icon_phone',
          text: 'Tel: +1 514 299 3153',
          adr: 'tel:+15142993153',
        },
        {
          id: 3,
          icon: 'icon_mail',
          text: 'Courriel: info@gpcsolutions.ca',
          adr: 'mailto:info@gpcsolutions.ca',
        },

        {
          id: 4,
          icon: 'icon_globe',
          text: 'Site web: https://gpcsolutions.ca',
          adr: 'http://gpcsolutions.ca',
        },
      ],
    },
  ],
  contacttitle: 'Contact Form',
};

export default jhonData;
