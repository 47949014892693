import React, { Component } from 'react';
import Reveal from 'react-reveal/Reveal/';
import MapContainer from './Map';

class Contact extends Component {
  render() {
    let jhonData = this.props.jhonData;
    return (
      <section className="contact-area" id="contact">
        <div className="container">
          <div className="row row-reverse">
            {jhonData.contact &&
              jhonData.contact.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-md-5">
                      <Reveal effect="fadeInLeft" duration={500}>
                        <div className="contact_info">
                          <h4>{item.title}</h4>
                          <ul className="nav">
                            {item.menuItems.map((info) => {
                              return (
                                <li className="item" key={info.id}>
                                  <div className="media">
                                    <a href={info.adr}>
                                      <i className={info.icon}></i>
                                    </a>
                                    <div className="media-body">
                                      <a href={info.adr}>{info.text}</a>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </Reveal>
                    </div>
                  </React.Fragment>
                );
              })}
            <div className="col-md-7">
              {/* <MapContainer /> */}
              {/* <img
                src="../image/contact.jpg"
                alt=""
                width="100%"
                height="500px"
              /> */}

              <Reveal effect="fadeInRight" duration={800}>
                <div className="input_form">
                  {/* <h4>{jhonData.contacttitle}</h4> */}
                  <form
                    action="contact-form-handler.php"
                    method="post"
                    id="contactForm"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          className="form-control"
                          placeholder="Votre nom*"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          placeholder="Votre courriel*"
                        />
                      </div>
                      {/* <div className="col-lg-6">
                        <input
                          type="text"
                          id="subject"
                          name="subject"
                          className="form-control"
                          placeholder="Subject*"
                        />
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          id="phone"
                          name="phone"
                          placeholder="Phone*"
                        />
                      </div> */}
                    </div>
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="6"
                      placeholder="Votre message..."
                    ></textarea>
                    <button
                      type="submit"
                      name="send"
                      className="btn send_btn theme_btn"
                    >
                      Envoyer
                    </button>
                  </form>
                  <div id="success">Merci, votre message a été envoyé !</div>
                  <div id="error">
                    Opps! Il y a quelque chose qui ne va pas. Veuillez
                    réessayer.
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Contact;
